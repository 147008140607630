<template>
  <div class="stats-ct">
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-sent" />
      <span>Sent</span>
      <span class="stat-number">
        {{ numberFilter(stats['Promotion:sent']) }}
      </span>
    </span>
    <span class="stat">
      <icon glyph="decision-no" />
      <span>Failed</span>
      <span class="stat-number">
        {{ numberFilter(stats['Promotion:failed']) }}
      </span>
    </span>
  </div>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  }
}
</script>
<style scoped>
.stats-ct {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat {
  display: flex;
  gap: 5px;
  align-items: center;
}

.stat-number {
  font-weight: 400;
}
</style>
