<template>
  <TabView @tab-change="generateKey">
    <TabPanel :header="$t('tabPanelSetup')">
      <div class="tab-ct">
        <div class="label-input-group">
          <span class="new-ui-label-no-width">
            {{ $t('smsToolboxLargeDefaultLanguage') }}
          </span>
          <language-selector
            :value="config.defaultLang"
            @input="setDefaultLang"
            :disabled="!isAvailable"
          />
        </div>
        <div class="label-input-group">
          <span class="new-ui-label-no-width">
            {{ $t('smsToolboxLargeAdditionalLanguages') }}
          </span>
          <multi-language-selector
            :key="config.defaultLang"
            :value="additionalLanguages"
            :disabled="!isAvailable"
            :default-language-code="config.defaultLang"
            @input="setLanguages"
          />
        </div>

        <div
          class="label-input-group"
          v-if="config.hasOwnProperty('promotion_id')"
        >
          <span class="new-ui-label-no-width">
            {{ $t('smsToolboxLargePromotionLabel') }}
          </span>
          <div>
            <el-autocomplete
              style="width: 100%"
              clearable
              :debounce="500"
              :placeholder="placeholder"
              :disabled="readOnly || !loaded"
              v-model="selectedPromotionName"
              :fetch-suggestions="querySearchPromotions"
              @select="updatePromotion"
              @clear="updatePromotion({})"
            />
          </div>
        </div>

        <div
          class="label-input-group"
          v-if="
            config.hasOwnProperty('promotion_amount_required') &&
            config.promotion_amount_required
          "
        >
          <span class="new-ui-label-no-width">
            {{ $t('smsToolboxLargePromotionAmountLabel') }}
          </span>
          <InputNumber
            mode="decimal"
            :use-grouping="false"
            :max-fraction-digits="2"
            v-model="config.promotion_amount"
            :disabled="readOnly"
          />
        </div>

        <div v-if="!consentPreferencesAvailable">
          <ignore-subscription
            root-class="label-switch-group"
            :read-only="readOnly"
            :value="config.ignore_subscription"
            @input="onIgnoreSubscriptionChange"
          />
        </div>

        <div class="label-input-group" v-if="consentPreferencesAvailable">
          <div class="label-switch-group">
            <span class="new-ui-label">{{ $t('consentPreferenceType') }}</span>
            <InputSwitch
              :disabled="readOnly"
              v-model="config.consent_preference.enabled"
            />
          </div>
          <select-consent-preference
            v-if="config.consent_preference.enabled"
            :value="config.consent_preference.preference"
            :channel="ConsentChannels.SMS"
            :preferences="consentPreferences"
            :read-only="readOnly"
            @input="updateConsentPreference"
          />
        </div>

        <national-exclusion-check-settings
          class="label-input-group"
          v-if="
            $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
            $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
          "
          v-model="config.exclusion"
          :disabled="readOnly"
        />
      </div>
    </TabPanel>
    <TabPanel :header="$t('tabPanelContent')">
      <div
        style="display: flex; justify-content: space-between; gap: 10px"
        :key="panelKeyState"
      >
        <div class="tab-ct" style="flex-grow: 1">
          <TabView
            :active-index="activeLanguageIndex"
            @tab-change="setPreviewLanguage"
            :key="getKey"
          >
            <TabPanel
              v-for="(lang, index) in sortedLanguages"
              :key="lang"
              :tab-index="index"
              :header="getLanguage(lang)"
            >
              <sms-message
                v-model="config.messages[lang]"
                :profile-attributes="profileAttributes"
                :is-default="lang === config.defaultLang"
                :lang="lang"
                :disabled="!isAvailable"
                :unsubscribe-url="generateUnsubscribeUrl"
                :opt-out-reply-enabled="config.include_opt_out_reply"
                :opt-out-message="getOptOutMessage(lang)"
                :shorten-url="getShortenedLink"
                :with-voucher-codes="(config.voucher_code_enabled || 0) === 1"
              />
            </TabPanel>
          </TabView>
          <div class="voucher-ct" v-if="voucherCodesEnabled">
            <div class="label-input-group">
              <span class="new-ui-label-no-width">
                {{ $t('smsToolboxLargeVoucherCodeType') }}
              </span>
              <select-barcode-type
                :disabled="!isAvailable"
                v-model="config.voucher_code_enabled"
              />
            </div>
            <div
              v-if="(config.voucher_code_enabled || 0) === 1"
              class="label-input-group"
            >
              <span class="new-ui-label-no-width">
                {{ $t('smsToolboxLargeVoucherCodePositionLabel') }}
              </span>
              <select-barcode-position
                :disabled="!isAvailable"
                v-model="config.voucher_code_position"
              />
            </div>
          </div>
          <div v-if="smsOptOutEnabled && !smsOptOutType">
            <div class="label-switch-group">
              <span class="new-ui-label">
                {{ $t('smsToolboxLargeIncludeUnsubscribeLinkLabel') }}
              </span>
              <InputSwitch
                v-model="config.include_unsubscribe"
                :disabled="readOnly"
              />
            </div>
          </div>
          <div v-if="smsOptOutEnabled && smsOptOutType && vonageEnabled">
            <div class="label-switch-group">
              <span class="new-ui-label">
                {{ $t('smsToolboxLargeIncludeOptOutReply') }}
              </span>
              <InputSwitch
                v-model="config.include_opt_out_reply"
                :disabled="readOnly"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="action-ct" style="text-align: right">
            <el-dropdown v-if="!readOnly" trigger="click">
              <div class="action-btn">
                <span>{{ $t('actions') }}</span>
                <icon style="margin-bottom: 2px;" glyph="chevron-down" size="14px"/>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setDialogVisible = true">
                    <i class="pi pi-file" />
                    {{ $t('applyTemplate') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <apply-template-dialog
              :visible="setDialogVisible"
              @setVisible="setDialogVisible = false"
              :channel="MessageTypes.SMS"
              :has-content="hasContent"
              @applyMessageTemplate="importTemplate"
            />
          </div>
          <div class="preview">
            <push-preview-section
              :key="getPreviewKey"
              platform="ios"
              :text="messageText"
              :title="getMessagePreviewTitle"
              :app-name="appName"
              :app-icon="appIcon"
              :new-frame="true"
            />
          </div>
          <div class="template-summary" v-tooltip:top="formatTemplateTooltip">
            {{ formatTemplateInfo }}
          </div>
        </div>
      </div>
    </TabPanel>
  </TabView>
</template>
<script>
import SelectConsentPreference from '@/components/SelectConsentPreference'
import DeviceSmsMessagePreview from '@/components/DeviceSmsMessagePreview'
import DevicePushPreview from '@/components/DevicePushPreview'
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import SmsMessage from '@/components/SmsMessage'
import ISO6391 from 'iso-639-1'
import SelectBarcodeType from '@/components/SelectBarcodeType'
import SelectBarcodePosition from '@/components/SelectBarcodePosition'
import IgnoreSubscription from '@/components/IgnoreSubscription'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings.vue'
import TemplateSection from '@/views/WorkflowEdit/ToolboxesLarge/TemplateSection'
import { MessageTypes } from './MessageTypesEnum'
import XpApi from '@/libs/XpApi'
import { ConsentChannels } from '@/enums/ConsentChannelEnum'
import _ from 'lodash'

import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'
import PushPreviewSection from '@/components/DevicePreview/PushPreviewSection'
import MessagesIosIcon from '@/images/messages-ios.svg'
import ApplyTemplateDialog from '@/components/ApplyTemplateDialog'
import moment from 'moment/moment'

export default {
  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi', 'contentApi'],

  components: {
    ApplyTemplateDialog,
    TemplateSection,
    IgnoreSubscription,
    SelectBarcodeType,
    SelectBarcodePosition,
    DeviceSmsMessagePreview,
    DevicePushPreview,
    LanguageSelector,
    MultiLanguageSelector,
    SmsMessage,
    NationalExclusionCheckSettings,
    SelectConsentPreference,
    TabView,
    TabPanel,
    InputSwitch,
    InputNumber,
    PushPreviewSection
  },

  mounted() {
    if (!this.smsOptOutEnabled || !this.vonageEnabled || !this.smsOptOutType) {
      this.config.include_opt_out_reply = false
    }
  },

  data() {
    const config = Object.assign(
      {
        defaultLang: 'en',
        languages: ['en'],
        messages: {
          en: {
            fromName: '',
            text: '',
            optOutMessage: ''
          }
        },
        exclusion: {
          check: false,
          category: null
        },
        include_opt_out_reply: false,
        reply_to_messages: [],
        ignore_subscription: 'default',
        consent_preference: {
          enabled: false,
          preference: null
        },
        voucher_code_enabled: 0,
        voucher_code_position: 1,
        templateInfo: null
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    // maintain BC with old SMS nodes
    if (!config.hasOwnProperty('include_unsubscribe')) {
      config.include_unsubscribe = false
    }

    // maintain BC with old SMS nodes
    if (!config.hasOwnProperty('include_opt_out_reply')) {
      config.include_opt_out_reply = false
    }

    // maintain BC with old SMS nodes
    config.languages.forEach((lang) => {
      if (!config.messages[lang].hasOwnProperty('optOutMessage')) {
        config.messages[lang]['optOutMessage'] = ''
      }
    })

    return {
      promotion: {},
      selectedPromotionName: '',
      loaded: false,
      MessageTypes,
      ConsentChannels,
      config,
      messagePreviewLanguage: '',
      panelKeyState: 1,
      activeLanguageIndex: 0,
      setDialogVisible: false
    }
  },

  methods: {
    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }
      this.config.defaultLang = lang
      this.updateConfig()
    },

    getOptOutMessage(lang) {
      if (!this.config.include_opt_out_reply) {
        return (this.config.messages[lang].optOutMessage = '')
      }
      let message = this.config.reply_to_messages.find(
        (message) => message.language === lang
      )
      this.config.messages[lang].optOutMessage = message
        ? message['message']
        : ''
      return this.config.messages[lang].optOutMessage
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
    },

    setUnsubscribeToggle(toggle) {
      this.config.include_unsubscribe = toggle.value
    },

    updatePromotion(selectedPromotion) {
      this.promotion = selectedPromotion
      this.selectedPromotionName = selectedPromotion
        ? this.promotionsLabel(selectedPromotion)
        : ''
      this.config.promotion_id = selectedPromotion ? selectedPromotion.id : ''
      if (this.promotion) {
        this.config.promotion_amount_required = this.promotion.amount_required
        this.config.promotion_duration_required =
          this.promotion.duration_required
        if (!this.promotion.amount_required) {
          this.config.promotion_amount = null
        }
        if (!this.promotion.duration_required) {
          this.config.promotion_duration = null
        }
      } else {
        this.config.promotion_amount_required = false
        this.config.promotion_duration_required = false
      }

      this.updateConfig()
    },

    onIgnoreSubscriptionChange(type) {
      this.config.ignore_subscription = type
    },

    updateConfig() {
      this.workflowApi.updateAction(this.action.id, { config: this.config })
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: {
          search: queryString,
          expand: 'can_add,can_remove,integration_name'
        }
      }).then(({ data }) => {
        const list = data.map((item) => {
          item.value = item.name
          return item
        })
        cb(list)
      })
    },

    convertSubscriptionSettings() {
      if (
        this.action.config.ignore_subscription === 'ignore' &&
        this.$store.getters.isFeatureEnabled('consentPreferences')
      ) {
        this.config.ignore_subscription = 'doNotIgnore'
        this.config.consent_preference = {
          enabled: true,
          preference: '0'
        }
        this.updateConfig()
      } else if (
        !this.$store.getters.isFeatureEnabled('consentPreferences') &&
        this.action.config.consent_preference &&
        this.action.config.consent_preference.enabled &&
        this.action.config.consent_preference.preference === '0'
      ) {
        this.config.ignore_subscription = 'ignore'
        this.config.consent_preference = {
          enabled: false,
          preference: null
        }
        this.updateConfig()
      }
    },

    updateConsentPreference(settings) {
      this.config.consent_preference.preference = settings.value
      this.config.consent_preference.preference_name = settings.name
      this.updateConfig()
    },

    promotionsLabel(option) {
      if (_.isEmpty(option)) {
        return ''
      }

      return option.integration_name + " (#" + option.external_id + ") - " + option.name
    },

    getLanguage(languageCode) {
      return ISO6391.getName(languageCode)
    },

    setPreviewLanguage({ index }) {
      this.messagePreviewLanguage = this.sortedLanguages[index]
    },

    generateKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      // This will make sure we go to the first tab as that will always be the default language tab
      this.panelKeyState += this.panelKeyState
      this.messagePreviewLanguage = ''
    },

    importTemplate(template) {
      if (this.readOnly) {
        return
      }

      this.setDialogVisible = false
      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/campaign-templates/${template.id}`, {
        params: { fields: 'id,title', lang: this.getCurrentLanguage, expand: 'message' }
      }).then(({ data: template }) => {
        this.config.messages[this.getCurrentLanguage].fromName = template.message.from
        this.config.messages[this.getCurrentLanguage].text = template.message.text
        this.config.include_unsubscribe = !!template.message.sms_unsubscribe

        this.config.templateInfo = this.config.templateInfo || {}
        this.config.templateInfo[this.getCurrentLanguage] = {
          templateId: template.id,
          templateTitle: template.title,
          whoApplied: this.$store.state.app.user.email,
          lastApplied: new Date().getTime()
        }

        const config = JSON.parse(JSON.stringify(this.config))
        this.workflowApi.updateAction(this.action.id, { config })
      })
    }
  },

  computed: {
    appIcon: function () {
      return MessagesIosIcon
    },
    appName: function () {
      return 'Messages'
    },
    placeholder() {
      return this.loaded
        ? this.$t('selectOptionPlaceholder')
        : this.$t('loadingPlaceholder')
    },

    isAvailable() {
      return !this.readOnly && this.$store.getters.smsEnabled
    },

    smsOptOutEnabled() {
      return this.$store.getters.isFeatureEnabled('smsOptOutEnabled')
    },

    smsOptOutType() {
      return this.$store.getters.isFeatureEnabled('smsOptOutType')
    },

    smsShortenURL() {
      return this.$store.getters.isFeatureEnabled('smsShortenURL')
    },

    vonageEnabled() {
      return this.$store.getters.isFeatureEnabled('vonageEnabled')
    },

    voucherCodesEnabled() {
      return (
        (this.workflow?.features?.voucher_codes || false) &&
        ((this.config?.voucher_code_enabled || 0) === 1 ||
          this.$store.getters.isFeatureEnabled('allowVoucherCodes'))
      )
    },

    messageText() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      let messageText = this.config.messages[language]?.text

      if (this.getShortenedLink && this.getShortenedLink !== '') {
        messageText = messageText.replace(/(https?:\/\/[^\s]+)/g, this.getShortenedLink)
      }

      if (this.generateUnsubscribeUrl && !this.getOptOutMessage(language)) {
        messageText = messageText + '\n' + this.generateUnsubscribeUrl
      }

      if ((this.config.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    getMessagePreviewTitle() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      return this.config.messages[language]?.fromName
    },

    sortedLanguages() {
      return [this.config.defaultLang, ...this.additionalLanguages]
    },

    getKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      return this.config.defaultLang + this.config.languages.length
    },

    getPreviewKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      return (
        this.config.defaultLang +
        this.config.languages.length +
        this.panelKeyState
      )
    },

    additionalLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    languages() {
      return this.config.languages
    },

    profileAttributes() {
      let attributes = this.$store.state.project.attributes

      if (attributes.length === 0) {
        return [{}]
      }

      return this.$store.state.project.attributes
    },

    consentPreferences() {
      return this.$store.state.project.consentPreferences
    },

    getShortDomain() {
      return this.$store.getters.getDomain('shortDomain')
    },

    generateUnsubscribeUrl() {
      if (this.getShortDomain && this.config.include_unsubscribe) {
        return `${this.getShortDomain}/cfhisu`
      }

      return ''
    },

    getShortenedLink() {
      if (this.getShortDomain && this.smsShortenURL) {
        return `${this.getShortDomain}/cfhisu`
      }

      return ''
    },

    consentPreferencesAvailable() {
      return this.$store.getters.isFeatureEnabled('consentPreferences')
    },

    hasContent() {
      return !!this.getMessagePreviewTitle || !!this.messageText
    },

    getCurrentLanguage() {
      return this.messagePreviewLanguage || this.config.defaultLang
    },

    formatTemplateTooltip() {
      const whoApplied = this.action.config.templateInfo?.[this.getCurrentLanguage]?.whoApplied
      if (!whoApplied) {
        return ''
      }

      const lastApplied = moment(this.action.config.templateInfo?.[this.getCurrentLanguage]?.lastApplied)
        .format('DD/MMM/YYYY HH:mm')

      return this.$t('templateAppliedBy', {
        whoApplied: whoApplied,
        lastApplied: lastApplied
      })
    },

    formatTemplateInfo() {
      const templateTitle = this.action.config.templateInfo?.[this.getCurrentLanguage]?.templateTitle
      if (!templateTitle) {
        return ''
      }

      return this.$t('templateCreatedFrom') + templateTitle
    }
  },

  watch: {
    languages: {
      immediate: true,
      handler(languages) {
        let changes = false
        languages.forEach((lang) => {
          if (!this.config.messages[lang]) {
            this.$set(this.config.messages, lang, {
              fromName: '',
              text: '',
              optOutMessage: ''
            })
            changes = true
          }
        })

        Object.keys(this.config.messages).forEach((lang) => {
          if (!languages.includes(lang)) {
            this.$delete(this.config.messages, lang)
            changes = true
          }
        })

        this.updateConfig()
      }
    },
    'config.exclusion': {
      handler() {
        this.updateConfig()
      },
      deep: true
    },
    'config.ignore_subscription': function () {
      this.updateConfig()
    },
    'config.consent_preference': {
      handler() {
        this.updateConfig()
      },
      deep: true
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
    if (this.config.promotion_id) {
      const foundPromotion = this.$store.state.project.promotions.find(
        (promotion) => promotion.id === this.config.promotion_id
      )
      if (foundPromotion === undefined) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: {
            id: this.config.promotion_id,
            expand: 'can_add,can_remove,integration_name'
          }
        }).then(({ data }) => {
          if (data.length === 0) {
            this.updatePromotion({})
          } else {
            this.promotion = data[0]
            this.selectedPromotionName = this.promotionsLabel(data[0])
          }
        })
      } else {
        this.promotion = foundPromotion
        this.selectedPromotionName = this.promotionsLabel(foundPromotion)
      }
    }
    this.$store.dispatch('fetchTemplates', MessageTypes.SMS)
    if (!this.readOnly) {
      this.convertSubscriptionSettings()
    }
    this.loaded = true
  },

  beforeDestroy() {
    if (this.isAvailable) {
      const config = JSON.parse(JSON.stringify(this.config))
      this.workflowApi.updateAction(this.action.id, { config })
    }
  }
}
</script>
<style lang="sass" scoped>
.el-autocomplete
  width: 50%
  padding-top: 2px

textarea
    resize: none

.setup-tab
    padding: 20px
    .multiselect, input
        width: 50%

.VoucherOption
    display: flex
    flex-direction: column
    padding-right: 0
    padding-left: 0
    margin-bottom: 15px

.new-ui-label
  color: #4b515f
  font-weight: 600
  width: 200px

.new-ui-label-no-width
  color: #4b515f
  font-weight: 600

.label-switch-group
  display: flex
  align-items: center
  gap: 100px

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

.tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 50%

.voucher-ct
  display: flex
  flex-direction: column
  gap: 20px

.action-btn
  display: flex
  color: #2563EB
  font-size: 14px
  font-weight: 700
  height: 40px
  gap: 10px
  align-items: center
  padding-bottom: 5px
  user-select: none

.el-popper
  margin-top: 0 !important

.template-summary
  user-select: none
  float: right
</style>
