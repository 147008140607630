var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('workflow-edit-nav',_vm._b({},'workflow-edit-nav',{
      workflow: _vm.workflow,
      status: _vm.status,
      history: _vm.history,
      members: _vm.members,
      isPreview: _vm.isPreview,
      selectedActions: _vm.selectedActions
    },false)),_vm._v(" "),_c('workflow-nav-new-ui',{attrs:{"status":_vm.status,"workflow":_vm.workflow,"history":_vm.history,"zoom":_vm.zoom,"map-visible":_vm.mapVisible},on:{"zoom-in":_vm.zoomIn,"zoom-out":_vm.zoomOut,"show-map":_vm.onMapToggle,"toggle-grab-mode":_vm.onToggleGrabMode}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.canShowSmallToolbox)?_c('toolbox-small',{attrs:{"workflow":_vm.workflow,"action":_vm.selectedAction,"read-only":_vm.readOnly},on:{"close":function($event){return _vm.selectedActions.deselect()}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.canShowLargeToolbox && _vm.showLargeToolbox)?_c('toolbox-large',{attrs:{"workflow":_vm.workflow,"action":_vm.selectedAction,"read-only":_vm.readOnly},on:{"close":function($event){return _vm.handleLargeToolboxClose()}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.selectedAction && _vm.showActionReporting)?_c('action-reporting',{attrs:{"workflow":_vm.workflow,"action":_vm.selectedAction,"read-only":_vm.readOnly},on:{"close":function($event){_vm.showActionReporting = false}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.showWorkflowReporting)?_c('workflow-reporting',{attrs:{"workflow":_vm.workflow},on:{"close":function($event){_vm.showWorkflowReporting = false}}}):_vm._e()],1),_vm._v(" "),_c('div',{ref:"viewport",staticClass:"workflow-viewport",class:{
      'workflow-has-selection': _vm.selectedAction,
      'workflow-readonly': _vm.readOnly,
      'workflow-grabonly': _vm.grabMode
    },on:{"wheel":_vm.onWheel}},[_c('div',{ref:"canvas",staticClass:"workflow-canvas",class:{ 'multi-select-cursor-allowed': _vm.canvasShiftHover },on:{"click":_vm.onClick,"mouseover":function($event){return _vm.onMouseOver($event, 'canvas')},"mousemove":function($event){return _vm.onMouseOver($event, 'canvas')},"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop}},[_c('add-action'),_vm._v(" "),_vm._l((_vm.workflow.actions),function(action){return _c(_vm.actionType(action),_vm._b({key:action.id,tag:"component"},'component',{
          action,
          workflow: _vm.workflow,
          readOnly: _vm.readOnly,
          selectedActions: _vm.selectedActions,
          grabMode: _vm.grabMode,
          erroredActions: _vm.erroredActions,
          sideBarActionDrag: _vm.sideBarActionDrag
        },false))})],2)]),_vm._v(" "),_c('transition',{attrs:{"name":"workflow-nav"}},[(
        !_vm.readOnly &&
        !_vm.showLargeToolbox &&
        !_vm.canShowSmallToolbox &&
        !_vm.showWorkflowReporting &&
        !_vm.showActionReporting
      )?_c('workflow-side-nav',{on:{"sidebar-action-drag":_vm.onSideBarActionDrag}}):_vm._e()],1),_vm._v(" "),(_vm.mapVisible)?_c('mini-map',{ref:"miniMap",on:{"changeFocus":_vm.focusPosition}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }