<template>
  <div class="stats-ct">
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-arrived" />
      <span>{{ $t('actionTooltipArrived') }}</span>
      <span class="stat-number">
        {{ numberFilter(stats['Event:completed']) }}
      </span>
    </span>
  </div>
</template>

<script>
import { numberFilter } from '@/helpers/filters'
export default {
  name: 'EventStat',

  props: {
    stats: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      numberFilter
    }
  }
}
</script>

<style scoped>
.stats-ct {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat {
  display: flex;
  gap: 5px;
  align-items: center;
}

.stat-number {
  font-weight: 400;
}
</style>
